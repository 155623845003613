import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../entity/user';
import { AuthenticationObj } from '../entity/authenticationobj';
import { UserService } from '../services/userservice.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  userLogin = new FormGroup({
    username: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8)
    ])
  });

  token: string;

  ngOnInit() {
    // check if token exists and redirects if true
    if (sessionStorage.getItem('token') || '') {
      this.router.navigate(['Users']);
    }
    // this.subscribeToLogout();
  }

  login() {
    if (this.userLogin.valid) {
      this.subscribeToAuthentication();
    } else {
      alert('Controlla i campi');
    }
  }

  subscribeToAuthentication() {
    const credentials = {
      username: this.userLogin.controls.username.value,
      password: this.userLogin.controls.password.value
    };

    this.userService.authentication(credentials).subscribe(
      data => {
        sessionStorage.setItem('token', data.token);
        this.userService.setLoggedUser(data);

        if (data.role === 'admin') {
          this.router.navigate(['Users']);
        } else {
          alert('This user has no administrator permissions!');
          return false;
        }
      },
      error => {
        alert('The credentials you entered are incorrect!');
        return false;
      }
    );
  }

  // TO DO:
  // onInit check if token exists --> go to users list
  // on submit:
  //    - form fields check --> no alerts needed
  //    - check if username exists
  //    - check if password is right
  //    - if not --> alert
  //    - if yes --> login
}
