export class User {
  _id: string;
  name: string;
  surname: string;
  username: string;
  password: string;
  licenseStart: string;
  licenseExpiration: string;
  licenseActive: boolean;
  role: string;
  type: string;

  constructor(_id: string,
              name: string,
              surname: string,
              username: string,
              password: string,
              licenseStart: string,
              licenseExpiration: string,
              licenseActive: boolean,
              role: string,
              type: string) {
      this._id = _id;
      this.name = name;
      this.surname = surname;
      this.username = username;
      this.password = password;
      this.licenseStart = licenseStart;
      this.licenseExpiration = licenseExpiration;
      this.licenseActive = licenseActive;
      this.role = role;
      this.type = type;
    }
}
