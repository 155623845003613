import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../services/userservice.service';
import { User } from '../entity/user';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.less']
})
export class ChangepasswordComponent implements OnInit {

  private user: User = this.userService.getLoggedUser();

  changePswForm = new FormGroup({
    oldPsw: new FormControl('', [
      Validators.required,
      Validators.minLength(8)]),
    newPsw: new FormControl('', [
      Validators.required,
      Validators.minLength(8) ])
  });

  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
  }

  changePSW() {
    if (this.changePswForm.valid) {
      this.subscribeToChangePassword();
    } else {
      alert('Controlla i campi');
    }
  }

  subscribeToChangePassword() {

    const passwords = {
      oldPassword: this.changePswForm.controls.oldPsw.value,
      newPassword: this.changePswForm.controls.newPsw.value
    };

    this.userService.changePassword(passwords).subscribe(
      data => {
        const newCredentials = {username : this.user.username, password: passwords.newPassword, force: true}
        this.userService.authentication(newCredentials).subscribe(
          data => {
            sessionStorage.setItem('token', data.token);
            this.userService.setLoggedUser(data);
            this.router.navigate(['Users']);
          }
        );
      },
      error => {
        console.log(error);
      }
    );
  }

  cancelUser() {
    this.router.navigate(['Users']);
  }
}
