import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UserService } from '../services/userservice.service';
import { Router } from '@angular/router';
import { User } from '../entity/user';

@Component({
  selector: 'app-userdetail',
  templateUrl: './userdetail.component.html',
  styleUrls: ['./userdetail.component.less']
})


export class UserdetailComponent implements OnInit {

  // Service injected in constructor
  constructor(private userService: UserService, private router: Router) {}

  // Input variable to display properties of an user
  @Input() user: User;

  // Output variable used to tell the parent component to refesh the user list after successful delete
  @Output() refreshUserList: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() {
  }



  // Method to edit user details
  editUser() {
    this.router.navigate(['EditUser/' + this.user._id]);
  }

  // Activate User
  toggleActivation(user: User) {

    this.user.licenseActive = !this.user.licenseActive;

    if (this.user.licenseActive) {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 31);
      this.user.licenseStart = today.toISOString();
      this.user.licenseExpiration = tomorrow.toISOString();
    } else {
      this.user.licenseStart = '';
      this.user.licenseExpiration = '';
    }

    // this.userService.updateUser(user);
    this.subscribeUpdateUserToServer(this.user);
    this.refreshUserList.emit(true);
  }

  // update user to server
  subscribeUpdateUserToServer(user: User) {
    this.userService.updateUserToServer(user).subscribe(
      data => {
    });
  }





}
