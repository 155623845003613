import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../services/userservice.service';
import { User } from '../entity/user';
import { DateserviceService } from '../services/dateservice.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.less']
})

export class EdituserComponent implements OnInit {

  // Input variable to display properties of an user
  @Input() user: User;
  private loggedUser: User;

  editUserForm = new FormGroup({
    name: new FormControl('', [
      Validators.required]),
    surname: new FormControl('', [
      Validators.required ]),
    username: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]), // email
    licenseStart: new FormControl(''),
    licenseExpiration: new FormControl(''),
    licenseActive: new FormControl(''),
    password: new FormControl('', [Validators.minLength(8)]),
    role: new FormControl(''),
    type: new FormControl()
  });

  dateFrom: string;
  dateTo: string;
  types: Array<string>;

  // Services injected in constructor
  constructor(private userService: UserService, private dateService: DateserviceService, private route: ActivatedRoute, private router: Router) {
  }


  // Initializes variables
  ngOnInit() {
    const id: string = this.route.snapshot.params.id;
    this.types = ['Dealer', 'Veolia'];
    this.subscribeToGetUserFromServer(id);
    this.loggedUser = this.userService.getLoggedUser();
  }

  // Method to update a user
  updateUser() {

    if (this.editUserForm.valid) {

      this.user.name = this.editUserForm.controls.name.value;
      this.user.surname = this.editUserForm.controls.surname.value;
      this.user.username = this.editUserForm.controls.username.value;
      this.user.role = this.editUserForm.controls.role.value ? 'admin' : 'user';
      this.user.type = this.editUserForm.controls.type.value;
      this.user.licenseActive = this.editUserForm.controls.licenseActive.value;
      this.user.licenseStart = this.dateService.dateFromInputToISO(this.editUserForm.controls.licenseStart.value);
      this.user.licenseExpiration = this.dateService.dateFromInputToISO(this.editUserForm.controls.licenseExpiration.value);

      if (this.editUserForm.controls.password.value !== '') {

        if (this.user.username ===  this.loggedUser.username) {
          alert('Use the link in the header to change your own password');
          this.editUserForm.controls.password.setValue('');
          return;
        } else {
          const credentials = {
            username: this.user.username,
            password: this.editUserForm.controls.password.value
          };
          this.subscribeToSetPassword(credentials);
        }
      }

      if (this.userService.checkUserData(this.user)) {
        this.subscribeToUpdateUserToServer(this.user);
      }

    } else {
      alert('There is an error.');
    }
  }

  // Method to cancel update user operation
  cancelUser() {
    this.router.navigate(['Users']);
  }

   // Method to delete the user
   deleteUser() {
     const id: string = this.route.snapshot.params.id;
     if (confirm('Do you really want to delete this user')) {
      this.subscribeTodeleteUserFromServer(id);
      this.router.navigate(['Users']);
    } else {
      return false;
    }
  }

  // gets the user from the service
  subscribeToGetUserFromServer(id) {
    this.userService.getUserFromServer(id).subscribe(
      data  => {
      this.user = data;

      let booleanRole: boolean;
      if (this.user.role === 'admin') {
        booleanRole = true;
      } else if (this.user.role === 'user') {
        booleanRole = false;
      }

      this.editUserForm.get('name').setValue(this.user.name);
      this.editUserForm.get('surname').setValue(this.user.surname);
      this.editUserForm.get('username').setValue(this.user.username);
      this.editUserForm.get('role').setValue(booleanRole);
      this.editUserForm.get('type').setValue(this.user.type);
      this.editUserForm.get('licenseActive').setValue(this.user.licenseActive);
      this.editUserForm.get('licenseStart').setValue(this.dateService.dateFromISOtoInput(this.user.licenseStart));
      this.editUserForm.get('licenseExpiration').setValue(this.dateService.dateFromISOtoInput(this.user.licenseExpiration));

    },
    error => {
      alert('Network error!');
      this.router.navigate(['Users']);
    });
  }

  subscribeToUpdateUserToServer(user) {

    if (user.licenseExpiration === undefined) {
      user.licenseExpiration = '';
    }

    this.userService.updateUserToServer(user).subscribe(
      data => {
        this.router.navigate(['Users']);
      },
      error => {
        console.log(error);

        alert('Network error!');
      }
    );
  }

  subscribeTodeleteUserFromServer(userId) {
    this.userService.deleteUserFromServer(userId).subscribe(
      data => {
        this.router.navigate(['Users']);
      },
      error => {
        alert('Network error, l\'utente non è stato cancellato');
      }
    );
  }

  subscribeToSetPassword(credentials) {
    this.userService.setPassword(credentials).subscribe();
  }

}
