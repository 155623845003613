import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../entity/user';
import { UserService } from '../services/userservice.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})

export class HeaderComponent implements OnInit {

  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router) { }


admin: User;

  ngOnInit() {
    this.admin = this.userService.getLoggedUser();
  }

  logout() {
    this.subscribeToGetUserFromServer(this.admin._id);
  }

  changePassword() {
    this.router.navigate(['ChangePassword'], { state: { user: this.admin } });
  }

// gets the user from the service
subscribeToGetUserFromServer(id) {
  this.userService.getUserFromServer(id).subscribe(data  => {
    const credentials = {
      username : this.admin.username,
      password : data.password
    };
    this.userService.logout(credentials).subscribe(
      data => {
        this.userService.removeLoggedUser();
        sessionStorage.removeItem('token');
        this.router.navigate(['Login']);
      });
  });
}

}
