import { Injectable } from '@angular/core';
import { User } from '../entity/user';

@Injectable({
  providedIn: 'root'
})
export class DateserviceService {

  constructor() { }

  // Convert from input data field to ISOString
  dateFromInputToISO(date: string) {
    if (date) {
      return new Date(date).toISOString();
    }
  }

  // Convert from Date Object to [YYYY-MM-DD] format to pass to the input[type=date] field
  dateFromISOtoInput(date: string) {
    if (date) {
      return date.substr(0, 10);
    }
  }


  // Compare two dates and returnes boolean
  compareActivationDates(licenseStart: string, licenseExpiration: string) {

    if (licenseStart !== undefined && licenseExpiration !== undefined) {
      const date1 = new Date(licenseStart);
      const date2 = new Date(licenseExpiration);

      if (date1 < date2) {
        return true;
      } else {
        alert('La data di scadenza della licenza deve essere seguente a quella di attivazione.');
        return false;
      }
    } else  if (licenseStart !== undefined && licenseExpiration === undefined) {
      return true;
    } else {
      alert('Almeno la data di attivazione licenza deve essere impostata');
      return false;
    }
  }


}


