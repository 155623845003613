import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/userservice.service';
import { Router } from '@angular/router';
import { User } from '../entity/user';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-userslist',
  templateUrl: './userslist.component.html',
  styleUrls: ['./userslist.component.less']
})

export class UserslistComponent implements OnInit {
  _listFilterBy: string;
  allUsers: User[];
  filteredList: User[];
  token: string;
  layoutHeight: boolean;

  // Service injected in constructor
  constructor(private userService: UserService, private router: Router) {}

  // Gets filter by value from the search box
  get listFilterBy(): string {
    return this._listFilterBy;
  }

  // Sets filter by value from the search box
  set listFilterBy(value: string) {
    this._listFilterBy = value;
    this.filteredList = this._listFilterBy ? this.performFilter(this._listFilterBy) : this.allUsers;
  }

  // Method to filter the users on basis of filter by value
  performFilter(filterBy: string): User[] {
    filterBy = filterBy.toLocaleLowerCase();
    // tslint:disable-next-line:max-line-length
    return this.allUsers.filter((user: User) => {
      if (user.name && user.username) {
        return user.name.toLocaleLowerCase().indexOf(filterBy) !== -1 || user.username.toLocaleLowerCase().indexOf(filterBy) !== -1;
      }
    });
  }

  // Initializes all users list from user service
  ngOnInit() {
    this.layoutHeight = true;
    this.subscribeToGetAllUsersFromServer();
  }

  // Method to add a user to the list
  addUser() {
    this.router.navigate(['AddUser']);
  }

  // Method to refresh the user list after successful delete

  refreshList() {
    this.subscribeToGetAllUsersFromServer();
  }

  // gets all users from the server
  subscribeToGetAllUsersFromServer() {
    this.userService.getUsersFromServer().subscribe(data  => {
    this.allUsers = data.data;
    this.filteredList = this.allUsers;
    this._listFilterBy = '';
    const timer = setTimeout(function() {
      const height = document.querySelector('.main-section-wrapper').clientHeight;
      this.layoutHeight = height > window.innerHeight ? true : false;
    }, 20);
    });
  }







}
