import { Injectable, OnInit } from '@angular/core';
import { User } from '../entity/user';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DateserviceService } from '../services/dateservice.service';
import { AuthenticationObj } from '../entity/authenticationobj';
import { ReturnStatement } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})



export class UserService {

  baseUrl = 'https://evaledlicense.herokuapp.com/';

  constructor(private http: HttpClient, private dateService: DateserviceService) { }




  // set logged user in localhost
  setLoggedUser(user) {
    sessionStorage.setItem('loggedUser', JSON.stringify(user));
  }

  // get logged user in localhost
  getLoggedUser() {
    return JSON.parse(sessionStorage.getItem('loggedUser'));
  }

  // delete logged user in localhost
  removeLoggedUser() {
    sessionStorage.removeItem('loggedUser');
  }

  // check if data are ok for submission
  checkUserData(user: User) {
    if (user.name && user.surname && user.username && user.password) {
      if (user.licenseActive) {
        return this.dateService.compareActivationDates(user.licenseStart, user.licenseExpiration);
      }
      return true;
    } else {
      return false;
    }
  }

  // Autenthicate
  authentication(credentials) {
    credentials.force = true;
    return this.http.post<AuthenticationObj>(this.baseUrl + 'authenticate', credentials);
  }

  // Logout
  logout(credentials) {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + sessionStorage.getItem('token')
    });
    return this.http.post(this.baseUrl + 'logout', credentials, {headers: header});
  }

  // check license of user
  checkLicense() {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + sessionStorage.getItem('token')
    });

    return this.http.get(this.baseUrl + 'license', {headers: header});
  }

  // get all users from server
  getUsersFromServer(): Observable<any> {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + sessionStorage.getItem('token')
    });
    return this.http.get(this.baseUrl + 'users', {headers: header});
  }

  // create a user
  addUserToServer(user: User): Observable<any> {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + sessionStorage.getItem('token')
    });
    return this.http.post(this.baseUrl + 'users', user, {headers: header});
  }

  // update user
  updateUserToServer(user: User): Observable<any> {

    const dataToSend = {
      _id: user._id,
      name: user.name,
      surname: user.surname,
      username: user.username,
      type: user.type,
      licenseActive: user.licenseActive,
      licenseStart: user.licenseStart,
      licenseExpiration: user.licenseExpiration,
      dealer: false,
      role: user.role
    };

    const header = new HttpHeaders({
      Authorization: 'Bearer ' + sessionStorage.getItem('token')
    });
    return this.http.put(this.baseUrl + 'users/' + user._id, dataToSend, {headers: header});
  }

  // read user
  getUserFromServer(id: string): Observable<any> {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + sessionStorage.getItem('token')
    });
    return this.http.get(this.baseUrl + 'users/' + id, {headers: header});
  }

  // delete user
  deleteUserFromServer(id: string): Observable<any> {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + sessionStorage.getItem('token')
    });
    return this.http.delete(this.baseUrl + 'users/' + id, {headers: header});
  }

  changePassword(oldAndNewPassword): Observable<any> {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + sessionStorage.getItem('token')
    });
    return this.http.post(this.baseUrl + 'changePassword', oldAndNewPassword, {headers: header});
  }

  setPassword(credentials): Observable<any> {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + sessionStorage.getItem('token')
    });
    return this.http.post(this.baseUrl + 'setPassword', credentials, {headers: header});
  }

}
