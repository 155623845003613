import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/userservice.service';
import { Router } from '@angular/router';
import { User } from '../entity/user';
import { DateserviceService } from '../services/dateservice.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.less']
})

export class AdduserComponent implements OnInit {

  addUserForm = new FormGroup({
    name: new FormControl('', [
      Validators.required]),
    surname: new FormControl('', [
      Validators.required ]),
    username: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]), // email
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8)]), // psw
    licenseStart: new FormControl(''),
    licenseExpiration: new FormControl(''),
    licenseActive: new FormControl(''),
    role: new FormControl(''),
    type: new FormControl()
  });

  // tslint:disable-next-line:variable-name
  _id: string;
  name: string;
  surname: string;
  username: string;
  password: string;
  licenseStart: string;
  licenseExpiration: string;
  licenseActive: boolean;
  role: string;
  type: string;
  types: Array<string>;


  // Services injected in constructor
  constructor(private userService: UserService, private dateService: DateserviceService, private router: Router) { }


  // Initializes variables
  ngOnInit() {
    this.types = ['Dealer', 'Veolia'];
    this.addUserForm.get('type').setValue(this.types[0]);
  }

  // Activate Dates fields
  activateDateFields(value) {
    if (value) {
      const today = new Date();

      this.addUserForm.get('licenseStart').setValue(this.dateService.dateFromISOtoInput(today.toISOString()));
      this.addUserForm.get('licenseExpiration').setValue('');

    } else {
      this.addUserForm.get('licenseStart').setValue('');
      this.addUserForm.get('licenseExpiration').setValue('');
    }
  }


  // Method to save a user

  saveUser() {

    const role = this.addUserForm.controls.role.value == true ? 'admin' : 'user';
    const licenseActive = (this.addUserForm.controls.licenseActive.value === true);

    const user = new User(
      this.makeRandomID(),
      this.addUserForm.controls.name.value,
      this.addUserForm.controls.surname.value,
      this.addUserForm.controls.username.value,
      this.addUserForm.controls.password.value,
      this.addUserForm.controls.licenseStart.value,
      this.addUserForm.controls.licenseExpiration.value,
      licenseActive,
      role,
      this.addUserForm.controls.type.value,
      );

    if (this.userService.checkUserData(user)) {
      this.subscribeToAddUserToServer(user);
    } else {
      alert('Ricontrolla i dati inseriti');
    }
  }

  // Method to cancel the add operation
  cancelUser() {
    this.router.navigate(['Users']);
  }

  // Create random id for the user
  makeRandomID(): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 10; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  // creating user on server
  subscribeToAddUserToServer(user) {
    this.userService.addUserToServer(user).subscribe(
      data => {
        this.router.navigate(['Users']);
    },
    error => {
      alert('Network error!');
    });
  }
}
