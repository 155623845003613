import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserslistComponent } from './userslist/userslist.component';
import { AdduserComponent } from './adduser/adduser.component';
import { EdituserComponent } from './edituser/edituser.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { ChangepasswordComponent } from './changepassword/changepassword.component';


const routes: Routes = [
  {path: 'Users', component: UserslistComponent, canActivate: [AuthGuard]},
  {path: 'Login', component: LoginComponent},
  {path: 'AddUser', component: AdduserComponent, canActivate: [AuthGuard]},
  {path: 'EditUser/:id', component: EdituserComponent, canActivate: [AuthGuard]},
  {path: 'ChangePassword', component: ChangepasswordComponent, canActivate: [AuthGuard]},
  {path: '**', redirectTo: 'Login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
